import { Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../../hooks/global';
import { fetchLogOut } from '../../../../../store/user/user';
import './Drawer.scss';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface IDrawer {
  drawOpened: any;
  toggleDrawer: any;
  list_: any;
}

export const TemporaryDrawer: React.FC<IDrawer> = ({
  drawOpened,
  toggleDrawer,
  list_,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoginAs = localStorage.getItem("loginAs");

  const handleDrawerClick = (goTo: string) => {
    if (goTo === 'My Profile') {
      navigate('/my-profile');
    }

    if (goTo === 'Profiles') {
      navigate('/dashboard');
    }

    if (goTo === 'Users Management') {
      navigate('/users-management');
    }

    if (goTo === 'Reports') {
      navigate('/reports-list');
    }

    if (goTo === 'Loads') {
      navigate('/loads');
    }

    if (goTo === 'Invoicing') {
      navigate('/invoicing');
    }

    if (goTo === 'Log out') {
      dispatch(fetchLogOut()).then(res => {
        if (res.type === 'logout/fulfilled') {
          navigate('/sign-in');
        }
      });
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {list_.map((text: any, index: any) => (
          <ListItem
            key={text}
            disablePadding
            onClick={() => handleDrawerClick(text)}
            className="drawer-item"
          >
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={'right'}
      open={drawOpened['right']}
      onClose={toggleDrawer('right', false)}
      className={`customDrawerComponent ${isLoginAs === 'yes' ? 'minHeightDrawer' : ''}`}
    >
      {list('right')}
    </Drawer>
  );
};
