import { Stack } from '@mui/material';
import React, { FC, useState, useEffect } from 'react';
import { Button, Input, Tabs } from '../../controls';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import GoogleIcon from '@mui/icons-material/Google';
import './SignUp.scss';
import { useNavigate, useLocation } from 'react-router';
import { fetchSignUp } from '../../../store/user/user';
import { useAppDispatch } from '../../../hooks/global';
import { useForm } from 'react-hook-form';
import PrivacyPolicy from './TermsAndConditions/PrivacyPolicy';
import UserAgreement from './TermsAndConditions/UserAgreement';
import { API_URI } from '../../../constants/api';
import { toast } from 'react-toastify';

const SignUp: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: any = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedRole, setSelectedRole] = useState('tc-admin');
  const [loading, setLoading] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const [openAgreement, setOpenAgreement] = useState(false);
  const [fromSocial, setFromSocial] = useState(false);
  const [emailFromSocial, setEmailFromSocial] = useState(null);

  const rolesList = [
    { value: 'tc-admin', label: 'Truck company', id: 'truckCompany' },
    { value: 'fa-admin', label: 'Freight Agent', id: 'freightAgentCompany' },
    {
      value: 'id-admin',
      label: 'Independent Dispatcher',
      id: 'independetDispatcherCompany',
    },
  ];

  const handleClickShowPassword = (type: string) => {
    if (type === 'confirm') {
      setShowConfirmPassword(!showConfirmPassword);
    } else {
      setShowPassword(!showPassword);
    }
  };

  const handleChangeRole = (ev: React.SyntheticEvent, newValue: string) => {
    setSelectedRole(newValue);
  };

  const goToSignIn = () => {
    navigate('/sign-in');
  };

  useEffect(() => {
    if (location && location?.state) {
      setFromSocial(location?.state?.fromSocial);
      setEmailFromSocial(location?.state?.emailFromSocial);
    }
  }, [location])

  useEffect(() => {
    // console.log(emailFromSocial, 'emailFromSocial')
  }, [emailFromSocial])

  const accessSocialToken = localStorage.getItem('social_access_token');
  const provider = localStorage.getItem('social_login_provider');

  const handleFetchError= (error: any) => {
    console.error('Error: ', error);
  }

  const signUpFromSocial = async (data: any) => {
    try {
      const response = await fetch(`${API_URI}social-login?access_token=${accessSocialToken}&provider=${provider}`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json; charset=utf-8',
        'X-Requested-With': 'XMLHttpRequest',
        'App-Key': '0003e444eb9b6c2e43413f2a00c53a'},
        body: JSON.stringify({
          user: {
            email: emailFromSocial ? emailFromSocial : data.userEmail.trim(),
            role: selectedRole,
            title: data.companyName.trim(),
            password: data.password.trim(),
          }
        }),
      });
      
      const result = await response.json();

      if (result?.message && result?.message?.length) {
        toast.info(result?.message);
        navigate('/sign-in');
      };

      if (result?.data?.resource) {
        const userData = {
          first_name: result?.data?.resource?.profile?.first_name,
          last_name: result?.data?.resource?.profile?.last_name,
          email: result?.data?.resource?.email,
          role: result?.data?.resource?.role?.alias,
          company_name: result?.data?.resource?.entity?.owner?.title,
          is_profile_filled: result?.data?.resource.entity.owner.is_profile_filled,
          creator_id: result?.data?.resource?.entity.creator_id,
          profile_id: result?.data?.resource?.profile_id,
          id: result?.data?.resource?.id,
        };
  
        localStorage.setItem('token', result?.data?.resource?.credentials?.access_token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('refresh_token', result?.data?.resource?.credentials?.refresh_token);
        localStorage.setItem('owner_id', JSON.stringify(result?.data?.resource?.entity?.owner_id));
        localStorage.setItem('entity_id', JSON.stringify(result?.data?.resource?.entity?.id));

        navigate('/dashboard');
      }
    } catch (error: any) {
      handleFetchError(error);
      if (error?.message && error?.message?.length) {
        toast.info(error?.message);
        navigate('/sign-in');
      }
    }
  }

  const onSubmit = async (data: any) => {
    setLoading(true);

    if (fromSocial) {
      signUpFromSocial(data);
    } else {
      dispatch(
        fetchSignUp({
          user: {
            email: data.userEmail.trim(),
            password: data.password.trim(),
            password_confirm: data.confirmPassword.trim(),
            role: selectedRole,
          },
          profile: {
            title: data.companyName.trim(),
          },
        }),
      ).then(res => {
        if (res.type === 'signup/fulfilled') {
          setTimeout(() => {
            navigate('/sign-in');
          }, 2000);
        } else {
          setLoading(false);
        }
      });
    }
  };

  const onHandleLoginWithFB = async () => {
    localStorage.setItem('social_login_provider', 'facebook');
    window.open(`${API_URI}auth/redirect-facebook`, "_self");
  };

  const onHandleLoginWithGoogle = async () => {
    localStorage.setItem('social_login_provider', 'google');
    window.open(`${API_URI}auth/redirect-google`, "_self");
  };

  return (
    <div className="signUpContainer l-popup">
      <PrivacyPolicy open={openPrivacy} setOpen={setOpenPrivacy}/>
      <UserAgreement open={openAgreement} setOpen={setOpenAgreement}/>
      <img
        src={require('../../../assets/images/main-logo.png')}
        alt="Logo"
        className="mainLogo"
      />

      <div className="signUpView">
        {fromSocial ? '' : <h1 className="signUpTitle">Register Now</h1>}

        <Tabs value={selectedRole} onChange={handleChangeRole} tabsList={rolesList} />

        <div className="signUpForm">
          <Stack spacing={1} className="authForm">
            <Input
              name="Company Name"
              id="companyName"
              type="text"
              className="textField"
              control={control}
              isError={Boolean(errors.companyName)}
              required={true}
              minLength={1}
              maxLength={50}
            />
            {emailFromSocial ? "" : <Input
              name="Email"
              id="userEmail"
              type="text"
              className="textField"
              control={control}
              isError={emailFromSocial ? false : Boolean(errors.userEmail)}
              required={emailFromSocial ? false : true}
              minLength={1}
              maxLength={50}
              withoutSpaces={true}
              defaultValue={emailFromSocial}
              disabled={emailFromSocial ? true : false}
            />}
            <Input
              name="Password (minimum 8 characters)"
              id="password"
              showPassword={showPassword}
              handleClickShowPassword={handleClickShowPassword}
              type="password"
              className="textField"
              control={control}
              isError={Boolean(errors.password)}
              required={true}
              minLength={8}
              maxLength={50}
              withoutSpaces={true}
            />
            {fromSocial ? "" : <Input
              name="Confirm Password"
              id="confirmPassword"
              watch={watch}
              showPassword={showConfirmPassword}
              handleClickShowPassword={() => handleClickShowPassword('confirm')}
              type="password"
              className="textField"
              control={control}
              isError={Boolean(errors.confirmPassword)}
              required={true}
              minLength={8}
              maxLength={50}
              withoutSpaces={true}
            />}
          </Stack>

          {fromSocial ? '' : <><div className="horizontalLine">
            <hr />
            <span>OR</span>
            <hr />
          </div>

          <div className="registrationBtns">
            {/* <Button
              title="Sign-In with Facebook"
              Icon={<FacebookRoundedIcon color="warning" />}
              onClick={() => onHandleLoginWithFB()}
              className="authBtn authBtn__fb"
              variant="outlined"
            /> */}
            <Button
              title="Sign-In with Google"
              Icon={<GoogleIcon color="primary" />}
              onClick={() => onHandleLoginWithGoogle()}
              className="authBtn"
              variant="outlined"
            />
          </div>

          <p className="agreeLinks">
            By clicking on Registration, you agree to our{' '}
            <span className="link" onClick={() => setOpenAgreement(true)}>User Agreement</span> and{' '}
            <span className="link" onClick={() => setOpenPrivacy(true)}>Privacy Policy</span>
          </p></>}

          <div className="registrationBtns">
            <Button
              title={fromSocial ? "Continue" : "Register Now"}
              onClick={handleSubmit(onSubmit)}
              type="submit"
              className="authBtn authBtn__login"
              variant="contained"
              id="registerNow"
              loading={loading}
            />
          </div>

          <div className="authLinks">
            <span>Already a Member,&nbsp;</span>
            <span onClick={goToSignIn} className="link">
              Sign-In Here
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
