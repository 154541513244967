import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URI } from '../constants/api';
import { store } from '../store/store';
import { refreshToken } from '../store/user/user';

const configureApi = (baseUrl?: string) => {
  const instance = axios.create({
    headers: {
      Accept: 'application/json',

      'Content-Type': 'application/json; charset=utf-8',
      'X-Requested-With': 'XMLHttpRequest',
      'App-Key': '0003e444eb9b6c2e43413f2a00c53a',
    },

    baseURL: baseUrl ? baseUrl : API_URI,
  });

  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    if (token && config.headers) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }

    return config;
  });

  instance.interceptors.response.use(
    function (response) {
      return response.data;
    },
    function (error) {
      const refresh_token = localStorage.getItem('refresh_token');

      if (error.response.status === 402 && error?.response?.data?.data?.resource?.payment_intend_id) {
        return error;
      } else if (error.response.status === 401 && error.response.statusText === 'Unauthorized') {
        if (refresh_token) {
          store.dispatch(refreshToken(refresh_token));
        }
      } else if (
        error?.response?.data?.errors?.message === 'The refresh token is invalid.'
      ) {
        localStorage.clear();
        window.location.reload();
      } else if (
        error?.response?.data?.errors['user.email'] &&
        error?.response?.data?.errors['user.email'][0]
      ) {
        if (error.response.data.errors['user.email'][0]?.includes('user.email')) {
          toast.error(
            error.response.data.errors['user.email'][0].replace('user.email', 'email'),
          );
        } else {
          toast.error(error.response.data.errors['user.email'][0]);
        }
      } else {
        if (
          error.response.statusText !== 'Unauthorized' ||
          error.respose?.data?.errors?.message !== 'The refresh token is invalid.'
        ) {
          toast.error(error.response.data.message);
        }
      }
      console.log(`http request error -> `, error);
    },
  );

  return instance;
};

export const apiWithBaseUrl = configureApi;

const api = configureApi();
export default api;
